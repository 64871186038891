@import "../../../style/colors.scss";

.label {
  display: block;
  font-size: 13px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  transform: translate(0, 5px);

  &.withIcon {
    transform: translate(-25px, 20px);
  }

  &.isFocus {
    color: $brand !important;

    &.green {
      color: $green !important;
    }

    &.orange {
      color: $orange !important;
    }
  }

  &.show {
    opacity: 1;
    visibility: visible;
    transform: translate(0, 0);
  }
}

:global(.ant-form-item-has-error) {
  .label {
    color: $error !important;
  }
}