@import '../../../style/colors.scss';

.inlineSelect {
  :global(.ant-select-selector) {
    border-left-color: transparent !important;
    border-right-color: transparent !important;
    border-top-color: transparent !important;
    box-shadow: none !important;
  }

  &.green {
    :global(.ant-select-selector) {

      &:focus,
      &:hover {
        border-bottom-color: $green !important;
      }
    }
  }

  &.green {
    :global(.ant-select-focused) {
      :global(.ant-select-selector) {
        border-bottom-color: $green !important;
      }
    }
  }

  &.orange {
    :global(.ant-select-selector) {

      &:focus,
      &:hover {
        border-bottom-color: $orange !important;
      }
    }
  }

  &.orange {
    :global(.ant-select-focused) {
      :global(.ant-select-selector) {
        border-bottom-color: $orange !important;
      }
    }
  }
}