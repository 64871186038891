@import "../../../style/colors.scss";
@import "../../../style/mixins.scss";

.sideMenu {
  position: fixed;
  bottom: 15px;
  z-index: 10;
  right: 1.5vw;

  animation-name: sideMenuAnimation;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: 1;

  @include respond(phone) {
    display: none;
  }
}

.list {
  list-style: none;
  background-color: rgba(255, 255, 255, 0.7);
  box-shadow: 0 5px 8.5px rgba(0, 0, 0, 0.2);

  padding: 12px 10px;
  text-align: center;
  min-width: 42px;
  border-radius: 25px;

  &::before {
    content: '';
    position: absolute;
    top: 16px;
    left: 50%;
    margin-left: -1px;
    width: 2px;
    height: 270px;
    background-color: $grey3;
    z-index: -1;
  }

  .listItem {
    display: block;

    &:not(:last-child) {
      padding-bottom: 20px;
    }

    &.isActive {
      .dot {
        background-color: $dark-brand;
        width: 24px;
        height: 24px;
        text-decoration: none;
        border-color: $dark-brand;
        position: relative;
      }

      .dotText {
        display: block;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%)
      }
    }

    .dot {
      display: block;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: $grey1;
      border: 2px solid $grey5;
      margin-right: auto;
      margin-left: auto;
      cursor: pointer;

      &.contactUs {
        background-color: $orange;
        border-color: $orange;
      }
    }

    .dotText {
      display: none;
      font-size: 15px;
      color: $white;

      &.isIcon {
        font-size: 14px;
      }
    }
  }
}

@keyframes sideMenuAnimation {
  0% {
    top: 100%;
    opacity: 0.4;
  }

  80% {
    top: 48%;
  }

  100% {
    top: 50%;
    opacity: 1;
  }
}