$brand: #1874ff;
$light-brand: #40a9ff;
$dark-brand: #002766;
$error: #ff4d4f;
$success: #52c41a;
$white: #ffffff;
$grey1: #eeeeee;
$grey2: #dddddd;
$grey3: #434343;
$grey4: #5b5b5b;
$grey5: #b5b5b5;
$header-grey: #333333;
$black: #000000;
$yellow: #fff566;
$green: #288b44;
$light-green: #a3d74a;
$purple: #9254de;
$orange: #e0750d;

$brand_colors: (
  brand: $brand,
  light-brand: $light-brand,
  dark-brand: $dark-brand,
  white: $white,
  grey1: $grey1,
  grey2: $grey2,
  grey3: $grey3,
  grey4: $grey4,
  header-grey: $header-grey,
  black: $black,
  yellow: $yellow,
  green: $green,
  light-green: $light-green,
  purple: $purple,
  orange: $orange
)
