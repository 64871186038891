@import '../../../style/mixins.scss';
@import "../../../style/colors.scss";

.socialButtons {
  margin: 50px 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center
}

.button.button.button {
  margin: 0 20px;
  font-size: 30px !important;
  width: 140px !important;
  height: 45px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-right-radius: 15px;
  border-bottom-left-radius: 15px;
  transition: all .2s;

  @include respond(phone) {
    width: 75px !important;
  }

  &.youtube {
    color: $error !important;
    border-color: $error !important;

    &:hover {
      color: $white !important;
      background-color: $error !important;
    }
  }

  &.facebook {
    color: $brand !important;
    border-color: $brand !important;

    &:hover {
      color: $white !important;
      background-color: $brand !important;
    }
  }

  &.instagram {
    color: $orange !important;
    border-top-color: #fd1d1d !important;
    border-left-color: #fd1d1d !important;
    border-right-color: #833ab4 !important;
    border-bottom-color: #833ab4 !important;

    &:hover {
      color: $white !important;
      background: linear-gradient(to bottom right, #fd1d1d, #833ab4)!important;
    }
  }
}

.icon {
  margin-top: 3.5px;
}
