.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1px;

  > div {
    flex: 1;

    &:not(:last-child) {
      margin-left: 20px;
    }
  }
}
