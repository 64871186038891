@import "../../../style/colors.scss";
@import "../../../style/mixins.scss";

.section {
  height: 100vh;
  padding-top: 30px;
}

.layout {
  height: 100%;
  margin-top: 200px;

  @include respond(phone) {
    margin-top: 100px;
  }
}