/**
 * ? MEDIA QUERIY MANAGER ?
   **     0-767px:         Phone
   **     768px-991px:       Tablet
   **     992px-1199px:      Laptop
   **     1200px-1459px:     Desktop
   **     1460px:            Desktop Large

   **   breakpoint argument choices:
    **      - phoneM
   **       - phone
   **       - tab
   **       - laptop
   **       - desktop
   **       - desktopL
*/

@mixin respond($breakpoint) {
    @if $breakpoint==phone {
        @media (max-width: 767px) {
            @content
        };
    }
    @if $breakpoint==tab {
        @media (min-width: 768px) and (max-width: 991px) {
            @content
        };
    }
    @if $breakpoint==laptop {
        @media (min-width: 992px) and (max-width: 1199px) {
            @content
        };
    }
    @if $breakpoint==desktop {
        @media (min-width: 1200px) and (max-width: 1459px) {
            @content
        };
    }
    @if $breakpoint==desktopL {
        @media (min-width: 1460px) {
            @content
        };
    }
}

@mixin ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@mixin firefox {
    @supports (-moz-appearance:none) {
        @content;
    }
}

@mixin maxWithResources {
  :global(img) {
    max-width: 100% !important;
    max-height: 100% !important;
    }

  :global(iframe) {
    max-width: 100% !important;
    max-height: 100% !important;
  }
}
