@import "../../../style/colors.scss";
@import "../../../style/mixins.scss";

.contactUs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;

  &.inRow {
    margin: 0 20px;
  }

  @include respond(phone) {
    flex-direction: column-reverse;
  }

  @include respond(tab) {
    flex-direction: column-reverse;
  }
}

.rightSide {
  width: 45%;
  margin-left: 10px;

  @include respond(phone) {
    margin: 15px 0 0 0;
    width: 100%;
  }

  @include respond(tab) {
    margin: 15px 0 0 0;
    width: 100%;
  }

  @include respond(laptop) {
    width: 60%;
  }
}

.title {
  color: $orange !important;
  display: block;
  font-size: 48px;

  @include respond(phone) {
    font-size: 26px !important;
  }

  @include respond(tab) {
    font-size: 45px !important;
  }
}

.text {
  @include respond(phone) {
    font-size: 15px !important;
  }

  @include respond(tab) {
    font-size: 16px !important;
  }

  font-size: 17px;
}

.image {
  display: block;
  max-width: 100%;
  max-height: 100%;
  height: auto;
  clip-path: polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%);

  @include respond(phone) {
    clip-path: initial;
  }

  @include respond(tab) {
    clip-path: initial;
  }

  @include respond(laptop) {
    transform: translateY(-25px);
  }
}