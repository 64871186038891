@import '../../style/colors.scss';
@import '../../style/mixins.scss';

.image {
  margin-top: -75px;
  opacity: 0.9;
  width: 100%;
  height: 450px;
  background-size: cover;
  background-position: center;
}

.content {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 850px;
  margin-bottom: 30px;
  padding: 20px 130px;
  box-shadow: 0 5px 8.5px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  margin-top: -120px;
  z-index: 1;
  background-color: $white;

  @include respond(phone) {
    padding: 20px 10px;
    border: 2px solid $dark-brand;
  }
}

.titleWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  text-align: center;

  @include respond(phone) {
    flex-direction: column-reverse;
    text-align: center;
  }

  .icon {
    display: block;
    font-size: 43px;
    color: $dark-brand;
    margin-right: 20px;

    @include respond(phone) {
      font-size: 35px;
    }
  }

  .title.title.title {
    display: block;
    font-size: 55px;
    color: $dark-brand;
    margin: 0;

    @include respond(phone) {
      font-size: 30px;
    }
  }
}

.approveText {
  display: block;
  margin-top: 25px;
}