@import "../../../style/mixins.scss";
@import "../../../style/colors.scss";

.carousel {
  margin-top: -75px;
}

.image {
  display: block;
  opacity: 0.9;
  clip-path: ellipse(1920px 93% at 50% 0);
  max-width: 100%;
  height: auto;
}

:global(.slick-dots) {
  button {
    background-color: $brand !important;
  }
}

.item {
  position: relative;
}

.textWrapper {
  position: absolute;
  top: 160px;
  right: 50px;
  display: flex;
  flex-direction: column;
  width: 50%;

  @include respond(phone) {
    top: 65px;
    width: 55%;
  }

  @include respond(tab) {
    top: 110px;
    width: 50%;
  }
}

.header {
  font-size: 70px !important;
  color: $header-grey;
  letter-spacing: 1px;
  display: block;
  // text-shadow: 2px 0 0 $white, -2px 0 0 $white, 0 2px 0 $white, 0 -2px 0 $white, 1px 1px $white, -1px -1px 0 $white, 1px -1px 0 $white, -1px 1px 0 $white;

  @include respond(phone) {
    font-size: 26px !important;
  }

  @include respond(tab) {
    font-size: 45px !important;
  }

  @include respond(laptop) {
    font-size: 50px !important;
  }

  @include respond(desktop) {
    font-size: 50px !important;
  }
}

.text {
  font-size: 25px !important;
  letter-spacing: 1px;
  display: block;

  @include respond(phone) {
    font-size: 18px !important;
  }

  @include respond(tab) {
    font-size: 20px !important;
  }
}

.skeleton {
  > * {
    width: 100vw !important;
    height: 900px !important;
    clip-path: ellipse(1920px 93% at 50% 0);

    @include respond(tab) {
      height: 500px !important;
    }

    @include respond(laptop) {
      height: 670px !important;
    }

    @include respond(desktop) {
      height: 700px !important;
    }
  }
}

:global(.slick-list, .slick-track) {
  height: 100% !important;
}