@import "../../../style/mixins.scss";
@import "../../../style/colors.scss";

.imageWrapper {
  position: relative;

  .image {
    width: 100%;
    height: auto;
    opacity: 0.9;
    background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
  }

  .imageTitle {
    display: block;
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 40px;

    @include respond(phone) {
      font-size: 26px;
    }
  }
}

.tab {
  font-size: 16px;
  margin-bottom: 20px;
  min-height: 200px;

  .tabContent {
    width: 70%;
    white-space: pre-line;

    @include respond(phone) {
      width: 100%;
    }
  }
}

.skeleton {
  >* {
    width: 100% !important;
    height: 350px !important;

    @include respond(phone) {
      height: 130px !important;
    }
  }
}
