@import '../../../style/mixins.scss';
@import "../../../style/colors.scss";

.menu {
  padding: 10px;
  background: $grey3;
  border-radius: 3px;
  cursor: pointer;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  @include respond(phone) {
    height: 35px;
    width: 35px;
    padding: 5px;
  }

  @include respond(tab) {
    height: 40px;
    width: 40px;
  }

  @include respond(laptop) {
    height: 45px;
    width: 45px;
  }

  .menuContent {
    transform: translateY(5px);

    @include respond(tab) {
      transform: translateY(4px);
    }

    @include respond(phone) {
      transform: translateY(3px);
    }
  }

  .hamburgerIcon {
    border-top: 1.5px solid $white;
    height: 9px;
    width: 30px;

    @include respond(phone) {
      height: 6px;
      width: 20px;
    }
  }

  .xIcon {
    height: 9px;
    width: 30px;
    position: relative;

    @include respond(phone) {
      height: 5px;
      width: 30px;
    }

    &::before {
      content: '';
      transform: rotate(-135deg);
      position: absolute;
      left: 0;
      width: 30px;
      height: 1px;
      background-color: $grey1;
    }

    &::after {
      content: '';
      transform: rotate(135deg);
      position: absolute;
      left: 0;
      width: 30px;
      height: 1px;
      background-color: $grey1;
    }
  }

  .contentConnect {
    position: absolute;
    height: 18px;
    width: 50px;
    background: $grey3;
    top: 20px;
    right: -10px;

    @include respond(tab) {
      top: 18px;
      height: 12px;
      width: 40px;
      right: -5px;
    }

    @include respond(laptop) {
      height: 13px;
      width: 45px;
      right: -7px;
    }
  }

  .content {
    position: absolute;
    width: 300px;
    background: $grey3;
    top: 37px;
    right: -10px;
    box-shadow: 0 10px 16px rgba(0, 0, 0, 0.3);

    @include respond(phone) {
      position: fixed;
      right: 0;
      right: -17px;
      height: 100vh;
      width: 100vw;
      top: 27px;
    }

    @include respond(tab) {
      top: 30px;
    }

    @include respond(laptop) {
      top: 32px;
      right: -8px;
    }
  }
}

.overlay {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 75px;
  background-color: rgba(53, 53, 53, 0.6);
  left: 0;
  z-index: -1;

  @include respond(laptop) {
    top: 65px;
  }

  @include respond(tab) {
    top: 60px;
  }

  @include respond(phone) {
    top: 55px;
  }
}

.list {
  list-style: none;
  margin-bottom: 0;
}

.selected {
  .item {
    background-color: $white;

    .text {
      color: $black !important;
    }
  }
}

.item {
  position: relative;
  padding: 10px 15px;

  &:not(.item1) {
    border-top: $grey4 1px solid;
  }

  &::before {
    content: '';
    height: 100%;
    width: 5px;
    position: absolute;
    top: 0;
    right: 0;
  }


  &:hover {
    background-color: $white;

    .text {
      color: $black;
    }
  }

  .text {
    font-size: 18px;
    color: $white;
  }

  &.item1::before {
    background-color: $light-brand;
  }

  &.item2::before {
    background-color: $yellow;
  }

  &.item3::before {
    background-color: $purple;
  }

  &.item4::before {
    background-color: $green;
  }

  &.item5::before {
    background-color: $dark-brand;
  }

  &.item6::before {
    background-color: $grey5;
  }

  &.item7::before {
    background-color: $brand;
  }

  &.item8::before {
    background-color: $error;
  }
}