.top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.rightSide {
  display: flex;
  align-items: center;
}
