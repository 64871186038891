@import "../../../style/colors.scss";

.list {
  list-style: none;
}

.item {
  margin-bottom: 20px;
}

.content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.input {
  margin-left: 20px !important;
}

.video {
  margin-left: 20px;
  width: 200px;
  height: 100px;
  border: none;
}

.plusIcon {
  font-size: 30px;
  cursor: pointer;
  color: $brand;
}

.deleteIcon {
  font-size: 25px;
}