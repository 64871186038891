@import "../../style/mixins.scss";

.grid {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 auto;
  width: 100%;

  @include respond(phone) {
    flex-direction: column;
  }
}

.gridItem {
  width: 33%;
  margin-bottom: 100px;
  text-align: center;

  @include respond(phone) {
    width: 100%;
  }

  .item {
    height: 200px;
  }
}

.skeleton {
  >* {
    width: 200px !important;
    height: 200px !important;
    border-radius: 50% !important;
  }
}