@import "../../../style/animations.scss";
@import '../../../style/mixins.scss';

.topBar {
  width: 100vw;
  padding: 0 15px;
  height: 75px;
  position: fixed;
  z-index: 1001;
  transition: all .2s;
  background: -webkit-linear-gradient(to bottom, #f0f0f0 0%, rgba(255, 255, 255, 0.9) 80%, white 100%);
  background: linear-gradient(to bottom, #f0f0f0 0%, rgba(255, 255, 255, 0.9) 80%, white 100%);

  &.isOnTop {
    background: -webkit-linear-gradient(to bottom, #f0f0f0 0%, rgba(255, 255, 255, 0.5) 80%, rgba(255, 255, 255, 0) 100%);
    background: linear-gradient(to bottom, #f0f0f0 0%, rgba(255, 255, 255, 0.5) 80%, rgba(255, 255, 255, 0) 100%)
  }

  @include respond(laptop) {
    height: 65px;
  }

  @include respond(tab) {
    height: 60px;
  }

  @include respond(phone) {
    height: 55px;
  }
}

.content {
  height: 100%;
  padding: 0 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include respond(laptop) {
    padding: 20px;
  }

  @include respond(tab) {
    padding: 10px;
  }

  @include respond(phone) {
    padding: 0;
  }
}

.rightSide {
  display: flex;
  align-items: center;
  justify-content: center;
}

.leftSide {
  display: flex;
  align-items: center;
}

.buttons {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.scrollDetected {
  visibility: hidden;
  width: 1px;
  height: 1px;
}