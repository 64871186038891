@import "../../../style/colors.scss";
@import "../../../style/mixins.scss";
@import "../../../style/animations.scss";

.floatContactUs {
  position: relative;
}

.button {
  position: fixed;
  transition: all .2s;
  background-color: $brand;
  top: 50%;
  padding: 15px 25px;
  border-radius: 20px;
  font-size: 30px;
  color: $white;
  cursor: pointer;
  left: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.3);
  z-index: 1000;
  transition: all .2s;

  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-duration: .7s;
  animation-duration: .7s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;

  &:hover {
    transform: scale(1.17);
  }

  &.popoverOpen {
    transform: scale(1.05);
    background-color: $dark-brand;
  }

  &.success {
    background-color: $success;
  }

  @include respond(phone) {
    font-size: 20px;
    padding: 8px 14px;
    top: 400px;
    left: 10px;
  }
}

.popoverContent {
  position: fixed;
  top: 50%;
  left: 136px;
  z-index: 1000;

  @include respond(phone) {
    top: 110px;
    right: 30px;
    left: 10px;
  }
}

.text {
  font-size: 20px;

  &.brand {
    color: $brand;
  }
}