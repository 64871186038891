@import "../../../style/colors.scss";
@import "../../../style/mixins.scss";

.section {
  height: 100vh;
  padding-top: 40px;
}

.lineWrapper {
  margin-top: 250px;
  overflow-y: auto;
  padding-top: 14px;
  width: 910px;

  :global(.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after) {
    background-color: $grey4;
  }

  @include respond(tab) {
    width: 100%;
  }

  @include respond(phone) {
    width: 100%;
  }
}

.dot {
  position: relative;
  display: block;
  width: 20px;
  height: 20px;
  cursor: pointer;
  border-radius: 50%;
  transform: translate(5px, -7px);
  border: 1px solid $grey4;
  transition: all .2s;
  background: url() -100px bottom/contain no-repeat, #f0f0fa;
  z-index: 2;

  .innerDot {
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: $grey4;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:hover {
    transform: translate(5px, -7px) scale(1.4);
    border-color: $dark-brand;

    .innerDot {
      background-color: $dark-brand;
    }
  }
}

.popover {
  width: 250px;

  .title {
    display: block;
    font-size: 25px;
  }

  .text {
    font-size: 15px;
  }
}