@import "../../../style/colors.scss";

.toolTip {
  transform-origin: 0 !important;
}

.imgWrapper {
  display: inline-block;
  position: relative;
  width: 200px;
  height: 200px;
  overflow: hidden;
  border-radius: 50%;
  border: 2px solid $grey1;
}

.imgHover {
  transition: all .2s;

  &:hover {
    transform: scale(1.1);
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.3);
    border-width: 1px;
  }
}

.title {
  cursor: pointer;
  display: block;
  font-size: 26px;
  transition: all .2s;

  &:hover {
    transform: scale(1.1);
  }
}

.header {
  display: block;
  text-align: center;
  margin-bottom: 40px !important;
  font-size: 45px;
}

.gridWrapper {
  margin-bottom: 100px;
}
