@import '../../style/mixins.scss';
@import '../../style/animations.scss';
@import "../../style/colors.scss";

.popover {
  position: relative;
}

.content {
  position: absolute;
  background: $white;
  padding: 15px;
  border: 1px solid $grey1;
  border-radius: 15px;
  left: 0;
  box-shadow: 0 10px 16px rgba(0, 0, 0, 0.3);
  top: 45px;

  &:not(.show) {
    pointer-events: none;
  }
}

.overlay {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  background-color: rgba(53, 53, 53, 0.6);
  left: 0;
  z-index: 10;
}