.video {
  width: 100%;
  height: 100%;
  border: none;
}

.item.item.item {
  height: auto;
  border: none;
}

.skeleton {
  width: 100% !important;
  height: 100% !important;

  >* {
    width: 100% !important;
    height: 100% !important;
  }
}
