@import "../../../style/colors.scss";
@import '../../../style/mixins.scss';

.carousel {
  width: 100% !important;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 20px;

  .text {
    font-size: 40px;

    @include respond(phone) {
      font-size: 30px;
    }
  }

  @include respond(phone) {
    flex-direction: column;
  }
}

.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon {
  transform: translateY(2px);
  font-size: 20px;

  @include respond(phone) {
    font-size: 18px;
  }
}

.button {
  transition: all .2s;

  &.rightArrow {
    border-top-right-radius: 15px;
    color: $brand;
    border-color: $brand;
  }

  &.leftArrow {
    border-bottom-left-radius: 15px;
    margin-right: 10px;
    color: $white !important;
  }

  &:hover {
    transform: scale(1.05);
  }
}

.itemsWrapper {
  padding: 20px 0;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;

  &.oneSlider {
    flex-direction: row;
  }
}

.item {
  overflow: hidden;
  width: 400px;
  height: 240px;
  margin: 0 20px;
  transition: all .2s;
  cursor: pointer;

  &:not(.isVideo) {
    border: 1px solid $grey2;

    &:hover {
      box-shadow: 0 5px 8px rgba(0, 0, 0, 0.3);
    }
  }

  &:hover {
    transform: scale(1.05);
  }
}