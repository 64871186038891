@import '../../../style/mixins.scss';
@import "../../../style/colors.scss";

.interesting.interesting.interesting {
  margin-left: 30px;
  font-size: 25px;
  transition: all .2s;
  color: black;
  cursor: pointer;

  @include respond(phone) {
    font-size: 25px;
  }

  @include respond(tab) {
    font-size: 28px;
  }

  &:hover {
    color: $brand;

    .interestingText {
      color: $brand;
    }
  }

  .interestingText {
    transition: all .2s;
    margin-right: 7px;
    font-size: 20px;
  }
}