@import "../../../style/colors.scss";
@import "../../../style/mixins.scss";

.numbers {
  min-height: 100vh;
  background-position: bottom !important;
  padding-top: 40px;
}

.layout.layout {
  min-height: 10px;
}

.content {
  display: flex;
  flex-direction: column;
}

.boxes {
  display: flex;
  margin-top: 20%;
  justify-content: space-between;
  flex-wrap: wrap;
}

.box {
  width: 20%;
  margin-bottom: 20px;
  background-color: rgba(255, 255, 255, .8);
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 5px;
  padding: 20px;
  transition: all .2s;


  &:hover {
    transform: scale(1.05);
  }

  @include respond(phone) {
    width: 45%;
  }

  .title.title.title {
    font-size: 90px;
    color: $orange;
    margin: 0;

    @include respond(phone) {
      font-size: 45px;
    }

    @include respond(tab) {
      font-size: 50px;
    }

    @include respond(laptop) {
      font-size: 70px;
    }
  }

  .divider {
    border-top: 2px solid $grey3;
    width: 20%;
    height: 2px;
    margin-bottom: 18px;
  }

  .text.text.text {
    font-size: 18px;

    @include respond(phone) {
      font-size: 15px;
    }

    @include respond(tab) {
      font-size: 16px;
    }

    @include respond(laptop) {
      font-size: 17px;
    }
  }
}