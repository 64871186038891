@import "../../style/mixins.scss";

.middleLayout {
  padding-top: 50px;

  @media (max-width: 1380px) {
    padding-top: 90px;
  };

  @include respond(phone) {
    width: 1000px !important;
  }
}