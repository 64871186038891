.row {
  padding: 0 15px;
  border-right: 5px solid;

  &:not(:first-child) {
    margin-top: 60px;
  }

  &:last-child {
    margin-bottom: 60px;
  }
}