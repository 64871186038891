@import '../../../style/mixins.scss';
@import '../../../style/colors.scss';

.button {
  width: 130px;
  margin-right: 5px;
  font-size: 18px;
  border-bottom-left-radius: 15px;

  @include respond(phone) {
    width: 35px;
    height: 35px;
    padding: 0;
  }

  @include respond(tab) {
    width: 110px;
    padding: 0;
  }

  @include respond(laptop) {
    padding: 0;
    width: 120px;
  }
}

