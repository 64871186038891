@import "../../../style/colors.scss";
@import "../../../style/mixins.scss";

.footer {
  width: 100%;
  min-height: 300px;
  background: $grey3;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.socialButton {
  display: flex;
  justify-content: center;
}

.layout {
  padding: 15px 0;

  @include respond(phone) {
    width: 100%;
  }
}

.content {
  display: flex;
  justify-content: space-between;
  flex: 1;
  width: 100%;

  @include respond(phone) {
    flex-direction: column;
    align-items: center;
  }
}

.listWrapper {

  @include respond(phone) {
    width: 100%;
    text-align: center;
  }
}

.appsForce {
  background-color: $black;
  width: 100%;
  bottom: 0;
  text-align: center;
}

.appsForceTitle {
  color: $white !important;
}

.list {
  list-style: none;

  .item {
    margin-bottom: 10px;
    font-size: 16px;
    cursor: pointer;

    * {
      color: $white;
      transition: all .1s;
      display: block;
    }

    &:hover * {
      text-decoration: underline;
      color: $light-brand;
    }
  }
}

.divider {
  border-color: $grey2;
  border-width: 2px;
}

.logo {
  width: 300px;
  height: auto;
}

.icons {
  display: flex;

  .facebook {
    margin-left: 15px;
    font-size: 29px;
  }

  .instagram {
    font-size: 30px;
  }

  .youtube {
    margin-right: 15px;
    font-size: 35px;
    margin-top: -3px;
  }

  .facebook,
  .instagram,
  .youtube {
    cursor: pointer;
    transition: all .2s;
    color: $white !important;

    &:hover {
      transform: scale(1.15);
    }
  }
}
