.icon {
  font-size: 20px;
  cursor: pointer;
}

.listItem {
  display: flex;
  list-style-type: none;
  z-index: 100000;
  &,
  * {
    direction: ltr !important;

  }
}

.checkbox {
  margin-bottom: 0px !important;
}

.buttons {
  display: flex;

  .submit {
    margin-left: 10px;
  }
}