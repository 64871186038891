@import "../../style/colors.scss";
@import "../../style/mixins.scss";

.navigation {
  position: fixed;
  height: 100vh;
  background-color: $grey3;
  display: flex;
  justify-content: center;

  @media (max-width: 1380px) {
    position: fixed;
    width: 100%;
    height: auto;
    z-index: 100;

    @include respond(laptop) {
      top: 65px;
    }
  
    @include respond(tab) {
      top: 60px;
    }
  
    @include respond(phone) {
      top: 55px;
    }
  };
}

.list {
  margin-top: 20px;
  list-style: none;

  @media (max-width: 1380px) {
    display: flex;
  };
}

.item {
  font-size: 17px;
  cursor: pointer;
}

.link {
  color: $white;
  padding: 5px 12px;
  display: block;
}

.selected {
  color: $light-brand;
}