@import '../../../style/mixins.scss';

.wrapper {
  width: 80%;
  margin: 0 auto;

  @include respond(phone) {
    width: 100%;
  }

  @include respond(tab) {
    width: 100%;
  }

  @include respond(laptop) {
    width: 100%;
  }

  @include respond(desktop) {
    width: 100%;
  }

  &.small {
    width: 60%;
    margin: 0 auto;

    @include respond(phone) {
      width: 100%;
    }

    @include respond(tab) {
      width: 75%;
    }

    @include respond(laptop) {
      width: 70%;
    }

    @include respond(desktop) {
      width: 70%;
    }
  }
}