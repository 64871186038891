.imageSlider {
  overflow-x: hidden;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.image {
  height: auto;
  display: block;
  max-width: none;
  opacity: 0.9;
  clip-path: ellipse(100% 100% at 50% 0);
}

.video {
  width: 100%;
  height: 100%;
  border: none;
}

.textWrapper {
  font-size: 18px;
  margin-top: 12px;
  padding: 0 7px 5px 7px;
  text-align: center;
}
