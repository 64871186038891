@import "../../style/mixins.scss";

.layout {
  padding-top: 40px;
  margin: 0 auto;
  width: 1350px;

  &.stretch {
    min-height: calc(100vh - 75px - 300px);
  }
  
  &.centerContent {
    display: flex;
    justify-content: center;
  }

  @include respond(phone) {
    width: auto;
    padding: 0 15px;
  }

  @include respond(tab) {
    width: 750px;
  }

  @include respond(laptop) {
    width: 970px;
  }

  @include respond(desktop) {
    width: 1170px;
  }

  @include respond(desktopL) {
    width: 1350px;
  }
}