@import '../../../style/mixins.scss';
@import "../../../style/colors.scss";

.login {
  position: relative;
}

.button {
  width: 130px;
  margin-right: 5px;
  font-size: 18px;
  border-top-right-radius: 15px;

  @include respond(phone) {
    width: 35px;
    height: 35px;
    padding: 0;
  }

  @include respond(tab) {
    padding: 0;
    width: 120px;
  }

  @include respond(laptop) {
    padding: 0;
    width: 120px;
  }
}

.icon {
  @include respond(phone) {
    transform: translateY(1px);
  }
}

.text {
  color: $white;
}
