@import "../../../style/colors.scss";

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 600px;
}

.gridWrapper {
  margin-bottom: 100px;
}

.textsWrapper {
  transition: all .2s;

  &.enable:hover {
    transform: scale(1.1);
  }
}

.title {
  display: block;
  margin-bottom: 0 !important;
  cursor: pointer;
  font-size: 25px;
}

.header {
  display: block;
  text-align: center;
  font-size: 45px;
  margin-bottom: 40px !important;
}

.toolTip {
  transform-origin: 0 !important;
}

.subTitle {
  display: block;
  margin-top: 0 !important;
  cursor: pointer;
  font-size: 17px;
}

.item {
  margin-bottom: 100px;
}

.img {
  width: auto;
  height: 100%;
  margin-left: -50px;
  cursor: pointer;
}

.notAllowed {
  cursor: not-allowed !important;
}

.imgWrapper {
  display: inline-block;
  position: relative;
  width: 200px;
  height: 200px;
  overflow: hidden;
  border-radius: 50%;
  border: 2px solid $grey1;
  transition: all .2s;
  
  &.enable:hover {
    transform: scale(1.1);
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.3);
    border-width: 1px;
  }
}
