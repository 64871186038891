@keyframes fadeIn {
  0% {
    transform: scale(1.2);
    opacity: 0.4;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes buzzOut {
  10% {
    -webkit-transform: translateX(3px) rotate(92deg);
    transform: translateX(3px) rotate(92deg);
  }

  20% {
    -webkit-transform: translateX(-3px) rotate(90deg);
    transform: translateX(-3px) rotate(90deg);
  }

  30% {
    -webkit-transform: translateX(3px) rotate(92deg);
    transform: translateX(3px) rotate(92deg);
  }

  40% {
    -webkit-transform: translateX(-3px) rotate(90deg);
    transform: translateX(-3px) rotate(90deg);
  }

  50% {
    -webkit-transform: translateX(2px) rotate(91deg);
    transform: translateX(2px) rotate(91deg);
  }

  60% {
    -webkit-transform: translateX(-2px) rotate(89deg);
    transform: translateX(-2px) rotate(89deg);
  }

  70% {
    -webkit-transform: translateX(2px) rotate(91deg);
    transform: translateX(2px) rotate(91deg);
  }

  80% {
    -webkit-transform: translateX(-2px) rotate(89deg);
    transform: translateX(-2px) rotate(89deg);
  }

  90% {
    -webkit-transform: translateX(1px) rotate(90);
    transform: translateX(1px) rotate(90);
  }

  100% {
    -webkit-transform: translateX(-1px) rotate(90);
    transform: translateX(-1px) rotate(90);
  }
}

@keyframes moveInLeft {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }

  80% {
    opacity: .5;
    transform: translateX(15px);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes moveInRight {
  0% {
    opacity: 0;
    transform: translateX(15rem);
  }

  80% {
    transform: translateX(-1rem);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes moveInButtom {
  0% {
    opacity: 0;
    transform: translateY(3rem);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}


@keyframes contactBtnMove {
  0% {
    top: 50%;
  }

  80% {
    top: 50%;
  }

  90% {
    top: 49%;
  }

  100% {
    top: 51%;
  }
}