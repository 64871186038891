@import './colors.scss';

@each $color, $val in $brand_colors {
  .color-#{$color} {
    color: $val !important;
  }

  .border-color-#{$color} {
    border-color: $val !important;
  }

  .background-color-#{$color} {
    background-color: $val !important;
  }
}
