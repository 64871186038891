.image {
  width: auto;
  height: 100%;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  &.notAllowed {
    cursor: not-allowed !important;
    opacity: .5;
  }
}
