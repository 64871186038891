@import "../../../style/animations.scss";
@import '../../../style/mixins.scss';
@import "../../../style/colors.scss";

.phone {
  position: relative;

  .icon {
    display: block;
    transform: rotate(90deg);
    font-size: 33px;
    margin-left: 5px;
    cursor: pointer;

    @include respond(phone) {
      font-size: 25px;
    }

    @include respond(tab) {
      font-size: 28px;
    }

    &:hover {
      svg {
        fill: $dark-brand;
      }
    }
  }

  .filled {
    display: none;
  }

  &:hover {
    .filled {
      display: block;
      -webkit-animation-name: buzzOut;
      animation-name: buzzOut;
      -webkit-animation-duration: 1s;
      animation-duration: 1s;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
      -webkit-animation-iteration-count: 1;
      animation-iteration-count: 1;
    }

    .outlined {
      display: none;
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
}