@import "../../../style/mixins.scss";
@import "../../../style/colors.scss";

.card {
  box-shadow: 0 0 3px $grey4;
  border: 1px solid $grey1;
  margin-bottom: 40px !important;

  @include respond(phone) {
    width: 100%;
  }
}

.modal {
  text-align: right !important;
}

.rightSide {
  width: calc(100% - 250px);

  @include respond(phone) {
    width: 100%;
  }
}

.sider {
  width: 200px;

  @include respond(phone) {
    display: none;
  }
}

.title {
  padding-top: 0 !important;

}

.content {
  &.withMenu {
    margin-right: 30px !important;

    @include respond(phone) {
      margin-right: 0 !important;
    }
  }

  :global(img) {
    max-width: 100% !important;
    max-height: 100% !important;
    height: auto;
  }

  :global(iframe) {
    max-width: 100% !important;
    max-height: 100% !important;
  }

  @include respond(phone) {
    margin-right: 0 !important;
  }


  :global(table) {
    :global(td) {
      padding: 5px 10px;
    }

    :global(img) {
      max-height: initial !important;
    }

    :global(iframe) {
      max-height: initial !important;
    }
  }
}

.wrapper {
  display: flex;
}

.questionsCard {
  margin-top: 20px !important;
  margin-right: 30px !important;

  :global(.ant-collapse-header) {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3) !important;
  }

  :global(.ant-collapse-item) {
    margin-bottom: 20px !important;
  }

  @include respond(phone) {
    margin-right: 0 !important;
  }
}

.answers {
  display: flex !important;
  flex-direction: column !important;
}

.questionHeaderTitle {
  color: #00a3ff !important;
}

.question {
  margin-bottom: 20px !important;
}

.option {
  margin-top: 5px !important;
  white-space: pre-line !important;
}
