@import '../../../style/mixins.scss';

.logoWrapper {
  margin-right: 20px;
  position: relative;
  cursor: pointer;

  @include respond(phone) {
    margin-right: 5px;
  }
}

.logo {
  height: 60px;

  @include respond(phone) {
    height: 38px;
  }
}

.cover {
  @include respond(phone) {
    width: 41px;
    overflow: hidden;
  }
}
