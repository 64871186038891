@import "./style/mixins.scss";
@import "./style/colors.scss";
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100&display=swap');
// @import url('https://fonts.googleapis.com/css?family=Varela+Round');
// @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,800&display=swap');

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

body {
  box-sizing: border-box;
  // font-family: 'Roboto', sans-serif;
  font-family: 'Rubik', sans-serif !important;
  // font-family: 'Varela Round', sans-serif !important;
  // font-family: 'Open Sans', sans-serif !important;
}

html {
  font-size: 62.5%; // 62.5% = 10px , 1rem=10px

  // @include respond(big-desktop) {
  //     font-size: 75%; //1rem = 12px , 12/16 = 75%;
  // }
  // @include respond(tab-land) {
  //     font-size: 56.25%; //1rem = 9px , 9/16 = 56.25%;
  // }
  @include respond(tab) {
    font-size: 56.25%; //1rem = 8px , 8/16 = 50%;
  }
}

.layout {
  min-height: 100vh;
}


.ant-layout {
  background: $white !important;
}
