@import "../../../style/colors.scss";

.button.button.button {
  width: 100px;
  height: 40px;
  border-radius: 6px;
  font-size: 17px;
  transition: all .2s;
  display: block;

  &.success {
    width: 140px;
  }

  &:not(:disabled) {
    &.brand {
      background-color: $white;
      color: $brand;
      border: 1px solid $brand;
    }

    &.green {
      background-color: $white;
      color: $green;
      border: 1px solid $green;
    }

    &.orange {
      background-color: $white;
      color: $orange;
      border: 1px solid $orange;
    }

    &:focus {
      &.green {
        background-color: $white;
        color: $green;
      }

      &.orange {
        background-color: $white;
        color: $orange;
      }

      &.brand {
        background-color: $white;
        color: $brand;
      }
    }

    &:hover,
    &:active {
      &.green {
        background-color: $green;
        color: $white;
        border: 1px solid $green;
      }

      &.orange {
        background-color: $orange;
        color: $white;
        border: 1px solid $orange;
      }

      &.brand {
        background-color: $brand;
        color: $white;
        border: 1px solid $brand;
      }

      transform: scale(1.05);
    }
  }
}

.buttonItem {
  margin-bottom: 0 !important;
}