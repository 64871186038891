@import "../../style/colors.scss";

.icon {
  margin-left: 5px;
}

.button {
  margin-bottom: 0 !important;
}

.buttonContent {
  display: flex;
  align-items: center;
}

.forgotPasswordLink {
  margin-right: 15px;
  cursor: pointer;
  color: $light-brand !important;
}

.back {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  transition: all .2s;

  &:hover {
    color: $light-brand;
  }
}

.backText {
  font-size: 16px;
}

.arrowIcon {
  margin-right: 7px;
  font-size: 16px;
}