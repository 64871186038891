@import '../../../style/colors.scss';

.inlintInput {
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  box-shadow: none !important;

  &.green {

    &:focus,
    &:hover {
      border-color: $green;
    }
  }

  &.orange {

    &:focus,
    &:hover {
      border-color: $orange;
    }
  }
}