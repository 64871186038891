@import "../../../style/mixins.scss";
@import "../../../style/colors.scss";

.header.header.header {
  font-size: 45px;
  display: block;
  margin-bottom: 10px;
  color: $brand;
  text-align: center;
}
