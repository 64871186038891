@import "../../../style/colors.scss";
@import "../../../style/mixins.scss";

.imageWrapper {
  background: url() center/contain no-repeat, #ededed;

  @include respond(phone) {
    min-height: auto;
  }

  @include respond(phone) {
    position: relative;
  }
}

.image {
  display: block;
  max-width: 100%;
  height: auto;
  opacity: 0.9;
  clip-path: ellipse(1920px 98% at 50% 0);
  margin-top: -75px;
}

.textWrapper {
  position: absolute;
  top: 6vw;
  right: 140px;
  width: 37%;
  white-space: pre-line;

  .title {
    font-size: 60px;
    color: $dark-brand;
    margin-bottom: 0 !important;
    text-shadow: 0 0 15px #fff;
  }

  .text {
    display: block;
    font-size: 23px;
    margin-top: -20px !important;
  }

  @include respond(phone) {
    top: -30px;
    right: 20px;
    width: 90%;

    .title {
      font-size: 27px;
    }

    .text {
      font-size: 14px;
    }
  }

  @include respond(tab) {
    top: 5px;
    right: 25px;
    width: 42%;

    .title {
      font-size: 45px;
    }

    .text {
      font-size: 15px;
    }
  }

  @include respond(laptop) {
    top: 30px;
    right: 30px;
    width: 42%;

    .title {
      font-size: 56px;
    }

    .text {
      font-size: 18px;
    }
  }

  @include respond(desktop) {
    top: 50px;
    right: 80px;

    .title {
      font-size: 57px;
    }

    .text {
      font-size: 20px;
    }
  }
}

.downButton {
  margin-top: 20px;
  width: 35px;
  height: 60px;
  border-radius: 20px;
  background-color: $dark-brand;
  color: $white;
  border: 1px solid $dark-brand;
  font-size: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  .icon {
    transform: translateY(6px);
  }

  &:hover {
    background-color: $white;
    color: $dark-brand;

    .icon {
      -webkit-animation-name: moveArrowDown;
      animation-name: moveArrowDown;
      -webkit-animation-duration: .7s;
      animation-duration: .7s;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
      -webkit-animation-iteration-count: infinite;
      animation-iteration-count: infinite;
    }
  }
}

@keyframes moveArrowDown {
  0% {
    transform: translateY(6px)
  }

  40% {
    transform: translateY(10px)
  }

  50% {
    transform: translateY(10px)
  }

  100% {
    transform: translateY(6px)
  }
}