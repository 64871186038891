@import "../../../style/colors.scss";

.section {
  min-height: 100vh;
  background-position: bottom !important;
  padding-bottom: 20px;
  padding-top: 40px;
}

.text1 {
  display: block;
  margin-bottom: 20px;
  color: $black;
}

.text2 {
  color: $black;
}

.header {
  display: block;
}

.textHeader {
  display: block;
  font-weight: 600;
  text-decoration: underline;
  color: $orange;
  font-size: 20px;
}