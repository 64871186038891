@import "../../style/colors.scss";
@import "../../style/mixins.scss";

.admin {
  background-color: $grey1;
  min-height: 100vh;
  
  @include respond(phone) {
    overflow: auto;
  }
}

.container {
  justify-content: center;
  display: flex;
}