@import "../../../style/colors.scss";
@import "../../../style/mixins.scss";

.header {
  border-right: 5px solid;
  padding-right: 25px;
  min-height: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .text.text.text {
    display: block;
    margin-top: 30px;
    font-size: 20px;
    white-space: pre-line;

    @include respond(phone) {
      font-size: 16px;
    }

    @include respond(tab) {
      font-size: 18px;
    }
  }

  &.center {
    align-items: center;

    .text.text.text {
      width: 60%;

      @include respond(phone) {
        width: 100%;
      }
    }
  }
}

.title.title.title {
  margin: 0;
  font-size: 55px;
  white-space: pre-line;

  @include respond(phone) {
    font-size: 30px;
  }

  @include respond(tab) {
    font-size: 45px;
  }
}