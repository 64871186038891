@import "../../../style/mixins.scss";

.content {
  width: 60%;
  margin: 0 auto;
}

.submitButton {
  margin-top: 20px
}

.tiny {
  margin: 20px 0 !important;
}

.header {
  padding-right: 0 !important;
}

.card {
  box-shadow: 0 0 3px darkgrey;
  border: 1px solid #ddd;

  @include respond(phone) {
    width: 100%;
  }
}
