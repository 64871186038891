@import '../../../style/mixins.scss';
@import "../../../style/colors.scss";

.profile {
  position: relative;
}

.button {
  margin-right: 5px;
  font-size: 18px;
  border-radius: 5px;

  @include respond(phone) {
    font-size: 18px;
    width: 37px;
    height: 37px;
    padding: 0;
  }
}

.list {
  list-style: none;
  margin-bottom: 0;
}

.item {
  padding: 5px 10px;
  cursor: pointer;
  font-size: 15px;

  &:hover {
    background-color: $grey1;
    border-radius: 15px;
  }
}