.answer {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.header {
  padding-right: 0 !important;
}

.wrapper {
  margin: 20px 0 !important;
}

.form {
  width: 100%;
}
